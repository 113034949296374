import ConnectButton from "./components/ConnectButton"
import AccountModal from "./components/AccountModal";
import { useDisclosure } from "@chakra-ui/react";
import Web3 from 'web3';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

declare var window: any
const { ethereum } = window;

function openDocs() {
    var win = window.open("https://docs.tigris.trade/", '_blank');
}

function openDiscord() {
    var win = window.open("https://discord.com/invite/tigris/", '_blank');
}

const networks = [
    {
        rpc: "https://polygon-rpc.com",
        name: "Polygon",
        network_id: 137,
        nativeTokenName: "Matic",
        nativeTokenSymbol: "MATIC",
        explorer: "https://polygonscan.com"
    },
    {
        rpc: "https://arb1.arbitrum.io/rpc",
        name: "Arbitrum",
        network_id: 42161,
        nativeTokenName: "ETH",
        nativeTokenSymbol: "ETH",
        explorer: "https://arbiscan.io"
    },
    {
        rpc: "https://goerli-rollup.arbitrum.io/rpc/",
        name: "Arbitrum Görli",
        network_id: 421613,
        nativeTokenName: "ETH",
        nativeTokenSymbol: "ETH",
        explorer: "https://goerli-rollup-explorer.arbitrum.io"
    },
];

function addNetwork(id:any) {
    const cNetwork = networks[id];
    const web3 = new Web3(new Web3.providers.HttpProvider(cNetwork.rpc));

    try {
        ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ 
                chainId: web3.utils.toHex(cNetwork.network_id),
                chainName: cNetwork.name,
                nativeCurrency: {
                    name: cNetwork.nativeTokenName,
                    symbol: cNetwork.nativeTokenSymbol,
                    decimals: 18
                },
                rpcUrls: [cNetwork.rpc],
                blockExplorerUrls: [cNetwork.explorer]
            }],
        })        
    } catch {
        console.log("No wallet");
    }
}

export default function Layout(Props:any) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Navbar expand="lg" className="header_area navbar-dark">
            <Navbar.Brand className="logo" style={{paddingLeft: '42px', paddingTop: '0px', paddingBottom: '0px'}}>
                <a style={{cursor: 'pointer', width: '180px', maxWidth: '180px'}}><img src="assets/images/logo.png" alt="logo" /></a>

                <button className="bar"><i className="fa fa-bars" aria-hidden="true"></i></button>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto m-menu">
                    <Nav.Link href="#" onClick={() => Props.handlePages(0)}>Trade</Nav.Link>
                    {Props.currentNetwork.network_id != 421613 ? (
                        <>
                            <Nav.Link href="#" onClick={() => Props.handlePages(6)}>Leaderboard</Nav.Link>
                            <Nav.Link href="#" onClick={() => Props.handlePages(3)}>Vault</Nav.Link>
                            <Nav.Link href="#" onClick={() => Props.handlePages(4)}>Governance</Nav.Link>
                            <Nav.Link href="#" onClick={() => Props.handlePages(5)}>Referral</Nav.Link>
                        </>
                    ) : (
                        <>
                            <Nav.Link href="#" onClick={() => Props.handlePages(2)}>Testnet Faucet</Nav.Link>
                        </>
                    )}
                    <Nav.Link href="#" onClick={() => Props.handlePages(0)}>Documentation</Nav.Link>
                    <Nav.Link href="#" onClick={() => Props.handlePages(0)}>Discord</Nav.Link>
                </Nav>
                <Nav className="me-auto menu">
                    <ul>
                        <li><a className={Props.activePage == 0 ? "active" : ""} href="#" onClick={() => Props.handlePages(0)}>Trade</a></li>
                        {Props.currentNetwork.network_id != 421613 ? (
                            <>
                                <li><a className={Props.activePage == 6 ? "active" : ""} href="#" onClick={() => Props.handlePages(6)}>Leaderboard</a></li>   
                                <li><a className={Props.activePage == 3 ? "active" : ""} href="#" onClick={() => Props.handlePages(3)}>Vault</a></li>
                                <li><a className={Props.activePage == 4 ? "active" : ""} href="#" onClick={() => Props.handlePages(4)}>Governance</a></li>
                                <li><a className={Props.activePage == 5 ? "active" : ""} href="#" onClick={() => Props.handlePages(5)}>Referral</a></li>
                            </>
                        ) : (
                            <>
                                <li><a className={Props.activePage == 2 ? "active" : ""} href="#" onClick={() => Props.handlePages(2)}>Testnet Faucet</a></li>
                            </>
                        )}
                        <li><a href="#" onClick={openDocs}>Documentation</a></li>
                        <li><a href="#" onClick={openDiscord}>Discord</a></li>
                    </ul>
                </Nav>
                <div className="con_sel_button">
                    <div className="select_box" id="select_box1">
                        <div className="dropdownbox" style={{minWidth: '130px'}}>
                            <img src={Props.currentNetwork.icon} alt="" />
                            <p>{Props.currentNetwork.name}</p>
                        </div>

                        <ul className="dropdown_select">
                            <li onClick={() => addNetwork(0)}>
                                <img src={"assets/images/polygon.png"} alt="" className="image"/>
                                <p>Polygon</p>
                            </li>
                            <li onClick={() => addNetwork(1)}>
                                <img src={"assets/images/arb.png"} alt="" className="image"/>
                                <p>Arbitrum</p>
                            </li>
                            <li onClick={() => addNetwork(2)}>
                                <img src={"assets/images/arbtest.png"} alt="" className="image"/>
                                <p>Arb Görli</p>
                            </li>
                        </ul>
                    </div>

                    <ConnectButton handleOpenModal={onOpen}/>
                    <AccountModal isOpen={isOpen} onClose={onClose} />
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
  }
