import { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEthers } from "@usedapp/core";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    plugins: {
        
    },
};


export default function DailyStats() {


    var labels:any = [];

    const [data, setData] = useState( {
        labels,
        datasets: [
            {
                label: '24hr pnl',
                data: [],
                borderColor: '#0145d1',
                backgroundColor: '#0145d1',
            },
        ],
    });

    const [dailyPositionsNumber, setDailyPositionsNumber] = useState(0);
    const [weeklyPositionsNumber, setWeeklyPositionsNumber] = useState(0);
    const [dailyPnl, setDailyPnl] = useState(0);
    const [weeklyPnl, setWeeklyPnl] = useState(0);
    const [updateChart, setUpdateChart] = useState(true);
    const { account } = useEthers();

    useEffect(() => {
        if(account == undefined) return;
        if(!updateChart) return; 
        setUpdateChart(false);

        fetch('https://tigris-e5mxb.ondigitalocean.app/stats-bot/daily/'+account)
        .then(response => {
            var labels:any = [];
            var values:any = [];
            response.json().then(data => {

                var info = data.info;
                setDailyPositionsNumber(info.dailyPositionsNumber);
                setWeeklyPositionsNumber(info.weeklyPositionsNumber);
                setDailyPnl(parseFloat((info.dailyPnl/1e18).toFixed(2)));
                setWeeklyPnl(parseFloat((info.weeklyPnl/1e18).toFixed(2)));

                data = data.data;
                console.log(data);
                for(var i=0; i<data.length; i++) {
                    values.push((data[i].payout-data[i].margin)/1e18);

                    var date = new Date(data[i].close_time * 1000);
                    var hours = date.getHours();
                    var minutes = "0" + date.getMinutes();

                    labels.push(hours + ':' + minutes.substr(-2));
                }

                setData({
                    labels,
                    datasets: [
                        {
                            label: '24hr pnl',
                            data: values,
                            borderColor: '#0145d1',
                            backgroundColor: '#0145d1',
                        },
                    ],
                });
            });
        });
    }, [account]);

    setInterval(() => {setUpdateChart(true)}, 30000);

    return (
        <div className="price_wave_table">
            <div className="performance">
                <select>
                    <option>Daily Performance</option>
                </select>
            </div>

            <div className="pnl_box">
                <ul>
                    <li>Daily PNL: <span>${dailyPnl}</span></li>
                    <li>Weekly PNL: <span>${weeklyPnl}</span></li>
                    <li>Number of Settled Positions(24hr): <span>{dailyPositionsNumber}</span></li>
                    <li>Number of Settled Positions(7d): <span>{weeklyPositionsNumber}</span></li>
                </ul>
            </div>

            <div className="btc_items">
                <div style={{ width: '100%', background: '#0B0E11', padding: '15px 15px', borderRadius: '10px'}}>
                    <div className="btc_body">
                        <Bar options={options} data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
  }